import { List, Typography, Divider ,Row,Col} from 'antd';
import Item from '../json/Learn1082.json';
import Ntue_learn_template_book from './Ntue_learn_template_book';
import Ntue_learn_template_other from './Ntue_learn_template_other';
import Ntue_learn_template_speech from './Ntue_learn_template_speech';
import Ntue_learn_template_excibition from './Ntue_learn_template_excibition';
export default function Ntue_learn_1082(){
    return(
        <div className="learn">
            <div className="tabs">
                <div className="tab">
                    <input type="checkbox" id="chck5"/>
                    <label className="tab-label" for="chck5">書籍</label>
                    <div className="tab-content">
                        <Row  >
                            {Item.map((AClass) => (
                                <Col
                                    span={24}
                                >
                                    <Ntue_learn_template_book Item = {AClass}/>
                                </Col>
                                ))}
                        </Row>
                    </div>
                </div>
                <div className="tab">
                    <input type="checkbox" id="chck6"/>
                    <label className="tab-label" for="chck6">講座</label>
                    <div className="tab-content">
                        <Row  >
                            {Item.map((AClass) => (
                                <Col
                                    span={24}
                                >
                                    <Ntue_learn_template_speech Item = {AClass}/>
                                </Col>
                                ))}
                        </Row>
                    </div>
                </div>
                <div className="tab">
                    <input type="checkbox" id="chck7"/>
                    <label className="tab-label" for="chck7">軟體 活動</label>
                    <div className="tab-content">
                        <Row  >
                            {Item.map((AClass) => (
                                <Col
                                    span={24}
                                >
                                    <Ntue_learn_template_other Item = {AClass}/>
                                </Col>
                                ))}
                        </Row>
                    </div>
                </div>

            </div>
        </div>
    );
}