
import {Row, Col } from 'antd';
import { Tabs , Timeline} from 'antd';
import Class_1 from './Ntue_class_1081';
import Learn_1 from './Ntue_learn_1081';
import Class_2 from './Ntue_class_1082';
import Learn_2 from './Ntue_learn_1082';
const { TabPane } = Tabs;

export default function Ntue_grade1(){
    return(
        <div>
            <div className="">
                <div className="container-midtern">
                    <div className="ntue-name">
                        <div className="grade-title">ＲＥＣＯＲＤ</div>
                        <div className="grade-subTitle">~大一時的我~</div>
                    </div>
                    <div className="grade-tab">
                        <Tabs defaultActiveKey="1"  centered >
                            <TabPane tab="上學期" key="1">
                                <div className="tabpane-title">上學期課表</div>
                                <Class_1/>
                                <div className="tabpane-title">自我學習</div>
                                <Learn_1/>
                            </TabPane>
                            <TabPane tab="下學期" key="2">
                                <div className="tabpane-title">下學期課表</div>
                                <Class_2/>
                                <div className="tabpane-title">自我學習</div>
                                <Learn_2/>
                            </TabPane>
                        </Tabs>
                    </div>

                </div>


            </div>

        </div>
    );
}