import {Link} from'react-router-dom';
import { Image, Card, Row, Col} from 'antd';

export default function DesignItemD( {DesignD}  ){
   
    
    return(
        <Link to={`/ItemDetailProduct/${DesignD.id}` }>
            <div className='item-box'>
                <Row className='mt-5 item-box-row'>
                    
                    <Col span={12}>
                        <div className='design-content'>
                            <div className='design-tag'>{DesignD.tag}</div>
                            <div className='design-name'>{DesignD.title}</div>
                            <div className='design-slogan'>{DesignD.slogan}</div>
                            <div className='design-award mt-5'>{DesignD.award0}</div>
                            <div className='design-award'>{DesignD.award1}</div>
                            <div className='design-award'>{DesignD.award2}</div>
                        </div>
                    </Col>

                    <Col span={12}>
                        <img className='project-image' width={200} src={DesignD.image}/>
                    </Col>
                </Row>
                
            </div>
        </Link>
        


    )
}