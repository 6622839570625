
import {Row, Col } from 'antd';
import React from 'react';
import {CaretDownFilled} from '@ant-design/icons';
import {Link} from 'react-router-dom';

export default function DesignItemDetailProgram({DesignF}){

    return(
        <div className='design-box-program'>
            <div className='design-detail-box'>
                <Row justify="center" align="middle">
                    <Col span={10} className='design-detail-words'>
                        <div className='design-detail-name'>{DesignF.name}</div>
                        <div className='design-detail-concept'>Introduction ..... </div>
                        <div className='design-detail-concept'>{DesignF.content}</div>
                        <div className='design-detail-concept'>What I made.....</div>
                        <div className='design-detail-concept'>{DesignF.reason}</div>

                        <Link to="/"><div className='link-btn '>Link</div></Link>
                    </Col>
                    <Col span={12} className='design-detail-video'>
                        <div className='design-video'>
                            <iframe
                                width="600"  // 设置视频宽度
                                height="450"  // 设置视频高度
                                src={DesignF.youtube} // 将 VIDEO_ID 替换为您要嵌入的YouTube视频的ID
                                frameborder="0"  // 用于消除边框
                                allowFullScreen  // 允许全屏
                            ></iframe>
                            
                        </div>
                    </Col>
                    
                </Row>
                <div className='arrow'><CaretDownFilled /></div>
            </div>
        
            
            
        </div>
        
    );
}