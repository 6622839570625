import { Layout } from 'antd';
import AppHeader from "../components/Header"
import AppFooter from "../components/Footer"
import DesignItemDetail from "../components/DesignItemDetailProgram";
import DesignF from "../json/DesignF.json";


const { Header, Content, Footer } = Layout;

function ItemDetailProgram({match}) {
  const item = DesignF.find(
    (x) => x.id === match.params.itemID
  );
  return (
    <Layout className="container main-layout">
      <Header className="layout-header">
        <AppHeader/>

      </Header>
      <Content className="layout-content">
        <DesignItemDetail DesignF = {item}/>
      </Content>
      <Footer className="layout-footer">
        <AppFooter/>
      </Footer>
    </Layout>
  );
}

export default ItemDetailProgram;
