
import {Row, Col } from 'antd';
import Ntue_class_template from './Ntue_class_template';
import Lesson from '../json/Lesson1082.json';

export default function Ntue_class_1082(){
    return(
        <div>
            <Row gutter={[0,0]} >
              {Lesson.map((AClass) => (
                  <Col
                    key={AClass.id}
                    span={4}
                  >
                    <Ntue_class_template Lesson = {AClass}/>
                  </Col>
                ))}
              </Row>
        </div>
    );
}