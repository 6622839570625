import {Row, Col } from 'antd';

import { Avatar, Card, Image, Popover, Tabs, Timeline } from 'antd';
import {Link} from 'react-router-dom';

import {HourglassOutlined} from '@ant-design/icons';

import widthImage from '../images/banner02.jpg';
import meImage from '../images/beca-only.png';

import icon3 from '../images/Craft.jpg';
import icon5 from '../images/Product.jpg';
import icon7 from '../images/Game.jpg';

import skill1 from '../images/skillIcon/ai.png';
import skill2 from '../images/skillIcon/ps.png';
import skill3 from '../images/skillIcon/ae.png';
import skill4 from '../images/skillIcon/pr.png';
import skill5 from '../images/skillIcon/xd.png';
import skill6 from '../images/skillIcon/figma.png';
import skill7 from '../images/skillIcon/ptc-creo.png';
import skill8 from '../images/skillIcon/keyshot.png';
import skill9 from '../images/skillIcon/maya.jpg';
import skill10 from '../images/skillIcon/unity.png';
import skill11 from '../images/skillIcon/react.png';
import skill12 from '../images/skillIcon/boot.png';
import skill13 from '../images/skillIcon/blender.png';
import skill14 from '../images/skillIcon/meta.png';

export default function Main(){
    const { Meta } = Card;

    return(
        <div>
            <div className="container-fluid ">
                <Row className="box-about">
                    <Col span={9}>
                        <div className="box-title">Hi ! I am </div>
                        <div className="box-slogan">Yu Chieh Sun</div>
                        <div className="box-content-p">I have always had a passion for design, and I am particularly interested in creating user-friendly and visually appealing interfaces that enhance the user experience. </div>
                        <Link to="/contact">
                            <div className='more-btn mt-40'>More...</div>
                        </Link>
                    </Col>
                    <Col span={7}>
                        <Image className='myself-image' preview={false} width={300} src={meImage}/> 
                    </Col>
                    <Col span={4}>
                        <div className="skill-popover">
                            <div className="skill-item">
                                <img className="skill-image" src={skill1} /> -- Illustrator
                            </div>
                            <div className="skill-item">
                                <img className="skill-image" src={skill2} /> -- Photoshop
                            </div>
                            <div className="skill-item">
                                <img className="skill-image" src={skill3} /> -- After Effects
                            </div>
                            <div className="skill-item">
                                <img className="skill-image" src={skill4} /> -- Premiere
                            </div>
                            <div className="skill-item">
                                <img className="skill-image" src={skill5} /> -- Adobe XD
                            </div>
                            <div className="skill-item">
                                <img className="skill-image" src={skill6} /> -- Figma
                            </div>
                        </div>
                        
                    </Col>
                    <Col span={4}>
                        <div className="skill-popover">
                            <div className="skill-item">
                                <img className="skill-image" src={skill7} /> -- Creo
                            </div>
                            <div className="skill-item">
                                <img className="skill-image" src={skill8} /> -- Keyshot
                            </div>
                            <div className="skill-item">
                                <img className="skill-image" src={skill9} /> -- Maya
                            </div>
                        </div>
                        <div className="skill-popover">
                            <div className="skill-item">
                                <img className="skill-image" src={skill10} /> -- Unity
                            </div>
                            <div className="skill-item">
                                <img className="skill-image" src={skill11} /> -- React
                            </div>
                            <div className="skill-item">
                                <img className="skill-image" src={skill12} /> -- Bootstrap
                            </div>
                            <div className="skill-item">
                                <img className="skill-image" src={skill13} /> -- Blender
                            </div>
                            <div className="skill-item">
                                <img className="skill-image" src={skill14} /> -- Meta quest
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div>
                <div className='container-fluid'>
                    <Row className='cate-box'>
                        <Col span={8}>
                            <Link to="/UIUX">
                                <div className='cate-title btn5'>
                                    <h4>UIUX project</h4>
                                </div>
                            </Link>
                        </Col>
                    
                        <Col span={8}>
                            <Link to="/play">
                                <div className='cate-title btn5'>
                                    <h4>Programme project</h4>
                                </div>
                            </Link>
                        </Col>
                    
                        <Col span={8}>
                            <Link to="/Craft">
                                <div className='cate-title btn5'>
                                    <h4>Handmake project</h4>
                                </div>
                            </Link>
                        </Col>
                        
                    </Row>
                </div>
            </div>
            <div className="container-fluid">
                <Row className="box-about">
                    <Col span={12}>
                        <div className='block'>
                            <div className='block-title'>EDUCATION</div>
                            <Timeline className='mt-20'>
                                <Timeline.Item color="#252422" dot={<HourglassOutlined />} >
                                    <div className='block-time'>2023 ~ now</div>
                                    <div className='block-item'>Master</div>
                                    <div className='block-name'>National Taiwan University of Science and Technology</div>
                                    <div className='block-class'>Information Design Group, Institute of Design</div>
                                </Timeline.Item>
                                <Timeline.Item color="#252422" dot={<HourglassOutlined />} >
                                    <div className='block-time'>2019 ~ 2023</div>
                                    <div className='block-item'>Bachelor</div>
                                    <div className='block-name'>National Taipei University of Education</div>
                                    <div className='block-class'>Department of Arts and Design</div>
                                </Timeline.Item>
                            </Timeline>
                        </div>
                        <div className='block'>
                            <div className='block-title'>EXPERIENCE</div>
                            <Timeline className='mt-20'>
                                <Timeline.Item color="#252422" dot={<HourglassOutlined />} >
                                    <div className='block-time'>07/2023-11/2023</div>
                                    <div className='block-item'>Front-end Web Design : Internship</div>
                                    <div className='block-name'>Oh! Design Ltd.</div>
                                    <div className='block-content mt-5'>-- Illustration design</div>
                                    <div className='block-content'>-- Web development and design by Wix Editor</div>
                                    <div className='block-content'>-- Front-end and back-end data linkage</div>
                                </Timeline.Item>
                                <Timeline.Item color="#252422" dot={<HourglassOutlined />} >
                                    <div className='block-time'>07/2023-11/2023</div>
                                    <div className='block-item'>VR Heart Interactive Development : Internship</div>
                                    <div className='block-name'>BDmobile Technology Co.</div>
                                    <div className='block-content mt-5'>-- Stability Training for VR Laparoscopic Surgery</div>
                                    <div className='block-content'>-- VR Cardiac Hot Spot Function</div>
                                    <div className='block-content'>-- VR Heart Dynamic Simulation </div>
                                </Timeline.Item>
                                <Timeline.Item color="#252422" dot={<HourglassOutlined />} >
                                    <div className='block-time'>07/2021-09/2021</div>
                                    <div className='block-item'>Product Design : Internship</div>
                                    <div className='block-name'>Fujian Jiashun Art Products Co. </div>
                                    <div className='block-content mt-5'>-- Ceramics for Everyday Use</div>
                                    <div className='block-content'>-- The Design of pots and pans</div>
                                </Timeline.Item>
                                <Timeline.Item color="#252422" dot={<HourglassOutlined />} >
                                    <div className='block-time'>06/2021-09/2021</div>
                                    <div className='block-item'>VR Heart Interactive Development : Internship</div>
                                    <div className='block-name'>SunYu Technology Co.</div>
                                    <div className='block-content mt-5'>-- Azure Web Planning and UI Design</div>
                                    <div className='block-content'>-- System Front-end Cutting Production</div>
                                </Timeline.Item>
                            </Timeline>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className='block'>
                            <div className='block-title'>AWARD</div>
                            <Timeline className='mt-20'>
                                <Timeline.Item color="#252422" dot={<HourglassOutlined />} >
                                    <div className='block-time'>2023</div>
                                    <div>
                                        <div className='block-race'>Red Dot Award: Brands & Communication Design</div>
                                        <div className='block-result'>Red Dot Award </div>
                                    </div>
                                    <div>
                                        <div className='block-race'>International Busan Design Award</div>
                                        <div className='block-result'>ibda Winner</div>
                                    </div>
                                    <div>
                                        <div className='block-race'>Global Design iT Award 2023</div>
                                        <div className='block-result'>Gold</div>
                                    </div>
                                    <div>
                                        <div className='block-race'>Global Student Innovation Challenge</div>
                                        <div className='block-result'>Best Presentation</div>
                                    </div>
                                    <div>
                                        <div className='block-race'>Reddot concept design</div>
                                        <div className='block-result'>Shortlist</div>
                                    </div>
                                    <div>
                                        <div className='block-race'>Ricoh "MIRAI" Challenge</div>
                                        <div className='block-result'>First Prize</div>
                                    </div>
                                    <div>
                                        <div className='block-race'>MUSE Design Awards  </div>
                                        <div className='block-result'>Sliver</div>
                                    </div>
                                    <div>
                                        <div className='block-race'>A Design Award</div>
                                        <div className='block-result'>Shortlist</div>
                                    </div>
                                    <div>
                                        <div className='block-race'>Taiwan Golden Pin Design Award</div>
                                        <div className='block-result'>Winner</div>
                                    </div>
                                    
                                </Timeline.Item>
                                <Timeline.Item color="#252422" dot={<HourglassOutlined />} >
                                    <div className='block-time'>2022</div>
                                    <div>
                                        <div className='block-race'>China UXD Award</div>
                                        <div className='block-result'>National Third Prize </div>
                                    </div>
                                    <div>
                                        <div className='block-race'>Architecture MasterPrize</div>
                                        <div className='block-result'>Student Winner</div>
                                    </div>
                                    <div>
                                        <div className='block-race'>Fintech Entrepreneurship Competition</div>
                                        <div className='block-result'>First Prize</div>
                                    </div>
                                    <div>
                                        <div className='block-race'>LEXUS Design Award</div>
                                        <div className='block-result'>Shortlist</div>
                                    </div>
                                    <div>
                                        <div className='block-race'>Germany UX Design </div>
                                        <div className='block-result'>Shortlist - 2 piece</div>
                                    </div>
                                    <div>
                                        <div className='block-race'>Young ones ADC</div>
                                        <div className='block-result'>Shortlist</div>
                                    </div>
                                    <div>
                                        <div className='block-race'>SAKURA AWARDS</div>
                                        <div className='block-result'>Masterpiece</div>
                                    </div>
                                    <div>
                                        <div className='block-race'>ATX Design</div>
                                        <div className='block-result'>Shortlist - Top 30</div>
                                    </div>
                                    
                                    
                                </Timeline.Item>
                                
                            </Timeline>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}