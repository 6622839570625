
import {Row, Col } from 'antd';

import DesignItemC from './DesignItemC';
import DesignC from '../json/DesignC.json';
import DesignItemD from './DesignItemD';
import DesignD from '../json/DesignD.json';

import { useContext } from "react";
import { StoreContext } from "../store"

import widthImage from '../images/banner02.jpg';

export default function Cate_UIUX(){
    const { state: { page: {title} } } = useContext(StoreContext);

 
    return(
        <div >
            <div className='boxtitle'>
                <img className="sub-width-image" src={widthImage} />
                <div className='title'> PROJECTS</div>
            </div>
            
            <div className='subtitle'> UI / UX Design</div>
            <div className="box-Design">
                <Row className="rwd-design">
                    {DesignC.map((CClass) => (
                    <Col
                        key={CClass.id}
                        md={{ span: 6 }}
                        lg={{ span: 12 }}
                        xl={{ span: 12 }}
                        xxl={{ span: 12 }}
                    >
                        <DesignItemC DesignC = {CClass}/>
                    </Col>
                        ))}
                </Row>
            </div>
            <div className="mb-60"></div>
            <div>
                <div className='subtitle'> Product Design</div>
                <div className="box-Design">
                    <Row className="rwd-design">
                        {DesignD.map((DClass) => (
                        <Col
                            key={DClass.id}
                            lg={{ span: 12 }}
                            xl={{ span: 12 }}
                            xxl={{ span: 12 }}
                        >
                            <DesignItemD DesignD = {DClass}/>
                        </Col>
                            ))}
                    </Row>
                </div>
                <div className="mb-60"></div>

            </div>

        </div>
    );
}