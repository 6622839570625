
import {Row, Col } from 'antd';
import { Tabs , Timeline} from 'antd';

export default function Ntue_class_template({Lesson}){
    return(
        <div>
            <ul class="timeline">
                <li class="timeline-event">
                    <label class="timeline-event-icon"></label>
                    <div class="timeline-event-copy">
                        <p class="timeline-event-thumbnail">{Lesson.week}</p>
                        <p><strong>{Lesson.name1}</strong><br/>{Lesson.intro1}</p>
                        <p><strong>{Lesson.name2}</strong><br/>{Lesson.intro2}</p>
                        <p><strong>{Lesson.name3}</strong><br/>{Lesson.intro3}</p>
                        <p><strong>{Lesson.name4}</strong><br/>{Lesson.intro4}</p>
                    </div>
                </li>
            </ul>
        </div>
    );
}