import { List, Typography, Divider ,Row,Col} from 'antd';
import Item from '../json/Learn1081.json';
import Ntue_learn_template_book from './Ntue_learn_template_book';
import Ntue_learn_template_other from './Ntue_learn_template_other';
import Ntue_learn_template_speech from './Ntue_learn_template_speech';
import Ntue_learn_template_excibition from './Ntue_learn_template_excibition';
export default function Ntue_learn_1081(){
    return(
        <div className="learn">
            <div className="tabs">
                <div className="tab">
                    <input type="checkbox" id="chck1"/>
                    <label className="tab-label" for="chck1">書籍</label>
                    <div className="tab-content">
                        <Row  >
                            {Item.map((AClass) => (
                                <Col
                                    span={24}
                                >
                                    <Ntue_learn_template_book Item = {AClass}/>
                                </Col>
                                ))}
                        </Row>
                    </div>
                </div>
                <div className="tab">
                    <input type="checkbox" id="chck2"/>
                    <label className="tab-label" for="chck2">展覽 活動</label>
                    <div className="tab-content">
                        <Row  >
                            {Item.map((AClass) => (
                                <Col
                                    span={24}
                                >
                                    <Ntue_learn_template_excibition Item = {AClass}/>
                                </Col>
                                ))}
                        </Row>
                    </div>
                </div>
                <div className="tab">
                    <input type="checkbox" id="chck3"/>
                    <label className="tab-label" for="chck3">講座</label>
                    <div className="tab-content">
                        <Row  >
                            {Item.map((AClass) => (
                                <Col
                                    span={24}
                                >
                                    <Ntue_learn_template_speech Item = {AClass}/>
                                </Col>
                                ))}
                        </Row>
                    </div>
                </div>
                <div className="tab">
                    <input type="checkbox" id="chck4"/>
                    <label className="tab-label" for="chck4">軟體 其他</label>
                    <div className="tab-content">
                        <Row  >
                            {Item.map((AClass) => (
                                <Col
                                    span={24}
                                >
                                    <Ntue_learn_template_other Item = {AClass}/>
                                </Col>
                                ))}
                        </Row>
                    </div>
                </div>

            </div>
        </div>
    );
}