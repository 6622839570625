
import {Row, Col , Timeline, Anchor} from 'antd';
import {Link} from 'react-router-dom';
import DesignItem from './DesignItem';
import DesignA from '../json/DesignA.json';

import { List, Typography, Divider} from 'antd';
import Item from '../json/Learn1081.json';
import Ntue_learn_template_book from './Ntue_learn_template_book';
import Ntue_learn_template_other from './Ntue_learn_template_other';
import Ntue_learn_template_speech from './Ntue_learn_template_speech';
import Ntue_learn_template_excibition from './Ntue_learn_template_excibition';
import Gallery from './Gallery';


export default function Main_ntue(){

    return(
        <div> 
            {/* <Link to="/Grade1" ><img src={y1} width={50} className="grade-image"/></Link> */}
            <div className="container-fluid">
                <div className='ntue-item'>
                    <Gallery/>
                </div>
            </div>
        </div>
    );
}