import {Link} from'react-router-dom';
import { Image, Card, Row, Col} from 'antd';


export default function DesignItemC( {DesignC}  ){
    
    return(
        <Link to={`/ItemDetail/${DesignC.id}` } >
            <div className='item-box '>
                <Row className='mt-5 item-box-row'>
                    
                    <Col span={12}>
                        <div className='design-content'>
                            <div className='design-tag'>{DesignC.tag}</div>
                            <div className='design-name'>{DesignC.title}</div>
                            <div className='design-slogan'>{DesignC.slogan}</div>
                            <div className='design-award mt-5'>{DesignC.award0}</div>
                            <div className='design-award'>{DesignC.award1}</div>
                            <div className='design-award'>{DesignC.award2}</div>
                        </div>
                    </Col>
                    
                    <Col  span={12}>
                        <img className='project-image' width={200} src={require(DesignC.image).default}/>
                    </Col>
                </Row>
                
            </div>
        </Link>
        


    )
}