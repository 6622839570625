
import {Row, Col } from 'antd';
import { Tabs , Timeline} from 'antd';

export default function Ntue_learn_template_other({Item}){
    return(
        <div>
            <div className="learn-tab-item">
                {Item.other}
            </div>
        </div>
    );
}