import './App.css';
import './UpdateStyle.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import Ntue from './pages/ntue';
import Grade1 from './pages/Grade1';

import Craft from './pages/Craft';

import Play from './pages/Play';
import ItemDetailProgram from './pages/ItemDetailProgram';

import UIUX from './pages/UIUX';
import ItemDetail from './pages/ItemDetail';
import ItemDetailProduct from './pages/ItemDetailProduct';

import Contact from './pages/contact';
import { StoreProvider } from "./store";

function App() {
  return (
    <StoreProvider >
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/ntue" component={Ntue} />
          <Route path="/grade1" component={Grade1} />

          <Route path="/craft" component={Craft} /> 
         
          <Route path="/play" component={Play} />
          <Route path="/ItemDetailProgram/:itemID" component={ItemDetailProgram} />

          <Route path="/UIUX" component={UIUX} />
          <Route path="/ItemDetail/:itemID" component={ItemDetail} />
          <Route path="/ItemDetailProduct/:itemID" component={ItemDetailProduct} />

          <Route path="/contact" component={Contact} />

        </Switch>
      </BrowserRouter>
    </StoreProvider>

    // craft: 工藝設計和平面設計


  );

}

export default App;
