import React, { useState } from 'react';
import NavBar from "./NavBar";
import NavBarRwd from "./NavBarRwd";
import { useContext } from "react";
import { StoreContext } from "../store"
import { Row, Col,  Drawer, Button } from 'antd';



export default function Header() {
  const { dispatch } = useContext(StoreContext);

    return (
      <div>
        <header className="header">
          <Row className="container">
            <Col flex="50px">
              <div className="site-name">Profolio</div> 
            </Col>
            <Col flex="auto">
              <NavBar/>
            </Col>
          </Row>
        </header>
      </div>
    );

  }