import {Link} from'react-router-dom';
import { Image} from 'antd';

export default function DesignItemB( {DesignB}  ){
    return(
        <div className="design-item">
            <div className='design-decoration'></div>
            <Image
                    className='design-image'
                    width={300}
                    src={DesignB.image}
                    preview={false}
            />
            <div>
                <div className='design-title'>{DesignB.name}</div>
                <div className='design-date'>{DesignB.date}</div>
            </div>
            
            
        </div>


    )
}