
import {Row, Col } from 'antd';

import DesignItemA from './DesignItem';
import DesignA from '../json/DesignA.json';
import DesignItemB from './DesignItemB';
import DesignB from '../json/DesignB.json';

import widthImage from '../images/banner02.jpg';

export default function Cate_Craft(){
    return(
        <div >
            <div className='boxtitle'>
                <img className="sub-width-image" src={widthImage} alt='01' />
                <div className='title'> PROJECTS</div>
            </div>
            
            <div className='subtitle'> Graphic Design</div>
            <div className="box-design">
                <Row gutter={[32,32]} className="rwd-design">
                    {DesignA.map((AClass) => (
                    <Col
                        key={AClass.id}
                        lg={{ span: 8 }}
                        xl={{ span: 8 }}
                        xxl={{ span: 6 }}
                    >
                        <DesignItemA DesignA = {AClass}/>
                    </Col>
                        ))}
                </Row>
            </div>
            <div className="mb-60"></div>
            
            <div className='subtitle'> Craft Design</div>
            <div className="box-design">
                <Row gutter={[32,32]} className="rwd-design">
                    {DesignB.map((BClass) => (
                    <Col
                        key={BClass.id}
                        lg={{ span: 12 }}
                        xl={{ span: 8 }}
                        xxl={{ span: 6 }}
                    >
                        <DesignItemB DesignB = {BClass}/>
                    </Col>
                        ))}
                </Row>
            </div>
            <div className="mb-60"></div>
            

        </div>
    );
}