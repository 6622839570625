import {Link} from'react-router-dom';
import { Image} from 'antd';


export default function DesignItemF( {DesignF}){
    return(
        <Link to={`/ItemDetailProgram/${DesignF.id}` }>
            <div>
                <div className="design-program-box">
                    {/* <Image
                        className='design-program-image-col2 '
                        width={270}
                        src={DesignF.image}
                        preview={false}
                    />
                    <div className='design-program-words'>
                        <div className='design-program-title'>{DesignF.name}</div>
                        <div className='design-program-date'>{DesignF.date}</div>
                        <div className='design-program-content'>{DesignF.name}</div> 
                    </div> */}
                </div>
            </div>
        </Link>


    )
}