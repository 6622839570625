
import {Row, Col } from 'antd';

import DesignItemE from './DesignItemE';
import DesignE from '../json/DesignE.json';
import DesignItemF from './DesignItemF';
import DesignF from '../json/DesignF.json';

import { useContext } from "react";
import { StoreContext } from "../store"

import widthImage from '../images/banner02.jpg';

export default function Cate_Play(){

    return( 
        <div>
            <div className='boxtitle'>
                <img className="sub-width-image" src={widthImage} />
                <div className='title'> PROJECTS</div>
            </div>
            <Row className='design-program'>
                <Col span={7} className='design-program-box'>
                    <div className='subtitle'>Basic</div>
                    {DesignE.map((EClass) => (
                        <Col
                            key={EClass.id}
                            span={24}
                        >
                            <DesignItemE DesignE = {EClass}/>
                        </Col>
                    ))}
                </Col>
                <Col span={15} className='design-program-box'>
                    <div className='subtitle'>Advanced</div>
                    <Row className='design-program-box-row'>
                        {DesignF.map((FClass) => (
                            <Col
                                key={FClass.id}
                                span={12}
                            >
                                <DesignItemF DesignF = {FClass}/>
                            </Col>
                        ))}
                    </Row>
                    
                </Col>
            </Row>
            <div className="mb-60"></div>
            

        </div>
    );
}