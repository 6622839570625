import { Layout } from 'antd';
import AppHeader from "../components/Header"
import AppFooter from "../components/Footer"
import Ntue_grade1 from "../components/Ntue_grade1";
import { useContext } from "react";
import { StoreContext } from "../store"


const { Header, Content, Footer } = Layout;

function Grade1() {
  const { state: { page: {title} } } = useContext(StoreContext);

  return (
    <Layout className="container main-layout">
      <Header className="layout-header">
        <AppHeader/>

      </Header>
      <Content className="layout-content">
        <Ntue_grade1/>
      </Content>
      <Footer className="layout-footer">
        <AppFooter/>
      </Footer>
    </Layout>
  );
}

export default Grade1;
