import { Layout } from 'antd';
import AppHeader from "../components/Header"
import AppFooter from "../components/Footer"
import DesignItemDetail from "../components/DesignItemDetail";
import { useContext } from "react";
import { StoreContext } from "../store"
import DesignC from "../json/DesignC.json";


const { Header, Content, Footer } = Layout;

function ItemDetail({match}) {
  const item = DesignC.find(
    (x) => x.id === match.params.itemID
  );
  return (
    <Layout className="container main-layout">
      <Header className="layout-header">
        <AppHeader/>

      </Header>
      <Content className="layout-content">
        <DesignItemDetail DesignC = {item}/>
      </Content>
      <Footer className="layout-footer">
        <AppFooter/>
      </Footer>
    </Layout>
  );
}

export default ItemDetail;
