import {Link} from'react-router-dom';
import { Image} from 'antd';


export default function DesignItemE( {DesignE}){
    return(
        <div className="design-program-box">
            <Image
                className='design-program-image'
                width={270}
                src={DesignE.image}
                preview={false}
            />
            <div className='design-program-words'>
                <div className='design-program-title'>{DesignE.name}</div>
                <div className='design-program-date'>{DesignE.date}</div>
                <div className='design-program-content'>{DesignE.content}</div>
                <div className='design-program-youtube'>
                    <a href={DesignE.youtube}>youtube link</a></div> 
            </div>
        </div>


    )
}